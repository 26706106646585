import React, {Component, cloneElement} from 'react';
import Login from '../forms/Login';
import Registration from '../forms/Registration';
import Spinner from './Spinner';
import styled from 'styled-components';
import Logo from './Logo';
import translator from "../translator";
import {Col, Row, Typography} from 'antd';


const {Title} = Typography;

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${props => props.background};
  position: relative;
  direction: ${props => props.direction};
  background-size: cover;
`;

const Inner = styled.div`
  width: 100%;
  min-height: 100vh;
  max-width: 400px;
  margin: 0 auto;
  
  @media screen and (max-width: 767px) {
    background-image: url("${props => props.backgroundImage}");
    background-size: cover;
    max-width: 100%;
    background-position: right;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const FormWrapper = styled.div`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  max-width: 400px;
  width: 100%;
  padding: 15px;

  @media screen and (max-width: 767px) {
    background-color: rgba(255, 255, 255, .6);
    position: relative;
    top: 0;
    transform: none;
    margin: 0 auto;
    
  }
`;

const SideBackground = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url("${props => props.backgroundImage}");
  background-size: cover;
  background-position: right;
  padding: 80px 40px;
  position: relative;
  
  h1 {
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    z-index: 1;
    position: absolute;
    font-size: 80px;
    line-height: 1;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
  
  .cover {
    width: 100%;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

class AuthWrapper extends Component {

  constructor (props, context){
    super (props, context);
    this.state = {
      form: props.form,
      loading: true
    }
  }

  componentDidMount(){
    if (window.location.pathname !== '/') {
      localStorage.setItem('loginRedirect', window.location.pathname);
    }
    this.setState({
      loading: false
    });
  }

  switchForm = (form) => {
    const {authorize, brand, lang} = this.props;
    let component;
    switch (form) {
      case 'login':
        component = <Login lang={lang} brand={brand} switchForm={this.switchForm} authorize={authorize}/>;
        break;
      case 'registration':
        component = <Registration lang={lang} brand={brand} switchForm={this.switchForm} authorize={authorize}/>
        break;
      default:
        component = <Login lang={lang} brand={brand} switchForm={this.switchForm} authorize={authorize}/>;
    }
    this.setState({
      form: component
    });
  }

  render () {
    const {loading, form} = this.state;
    const {authorize, brand, lang} = this.props;
    if (loading) return <Spinner/>;
    return (
      <Wrapper backgroundImage={brand.background} background={brand.style.secondary_color} direction={lang === 'ar' ? 'rtl' : 'ltr'}>
        <Row>
          <Col md={11} className={'hide-mob'}>
            <SideBackground backgroundImage={brand.background}>
              <div className="cover"/>
              {/*<h1>{translator[lang].ourPrograms}</h1>*/}
            </SideBackground>
          </Col>
          <Col md={13} xs={24}>
            <Inner backgroundImage={brand.background}>
              <FormWrapper>
                {cloneElement(form, {
                  switchForm: this.switchForm,
                  authorize: authorize
                })}
              </FormWrapper>
            </Inner>
          </Col>
        </Row>
      </Wrapper>
    );
  }

}

export default AuthWrapper;
