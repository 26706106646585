import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import Spinner from "./Spinner";
import requests from "../requests";
import {message} from "antd";
import User from "./User";


class SilentLogin extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            token: props.match.params.token
        }
    }

    async componentDidMount() {
        const {token} = this.state;
        const {authorize} = this.props;
        const r = await requests.post('/authorization/silent_login', {
            'token': token
        });
        if (r.status === 200) {
            const user = new User();
            user.authorize(r.data);
            authorize();
            window.location.href = '/profile';
        } else {
            window.location.href = '/';
        }
    }

    render() {
        return <Spinner/>
    }
}

export default withRouter(SilentLogin);