export default {
    en: {
        welcomeTo: 'Welcome to',
        pleaseSignIn: 'Please, log in',
        email: 'Email',
        password: 'Password',
        logIn: 'Log In',
        noAccount: 'Don\'t have an account?',
        signUp: 'Sign Up',
        forgotPassword: 'Forgot password',
        notRobot: 'We trust you, but still you must prove you are not a robot.',
        pleaseSignUp: 'Please, sign up',
        pleaseEmail: 'Please input your email!',
        pleasePassword: 'Please input your password!',
        pleaseFirstName: 'Please input your first name!',
        pleaseLastName: 'Please input your last name!',
        firstName: 'First name',
        lastName: 'Last name',
        pleasePhone: 'Please input your phone!',
        phone: 'Phone',
        pleaseTerms: 'Please, accept terms and conditions',
        iAccept: 'I accept',
        tAndC: 'terms and conditions',
        pleasePolicy: 'Please, accept privacy policy',
        pp: 'privacy policy',
        alreadyHave: 'Already have an account?',
        signIn: 'Sign In',
        myCourses: 'My Courses',
        buyCourses: 'Buy Courses',
        payments: 'Payments',
        affiliation: 'Affiliation',
        profile: 'Profile',
        logOut: 'Log out',
        purchased: 'Purchased',
        viewCourse: 'View Course',
        buy: 'Buy',
        noCourses: 'You have no purchased courses',
        buyNow: 'Buy Now',
        unexpectedError: 'Unexpected error happened',
        course: 'Course',
        created: 'Created',
        amount: 'Amount',
        currency: 'Currency',
        status: 'Status',
        new: 'New',
        pending: 'Pending',
        completed: 'Completed',
        failed: 'Failed',
        hoverStatus: 'Hover status to get details',
        join: 'Join',
        name: 'Name',
        country: 'Country',
        registrationDate: 'Registration date',
        date: 'Date',
        banner: 'Banner',
        code: 'Code',
        action: 'Action',
        codeCopied: 'Code copied to clipboard!',
        copyCode: 'Copy code',
        landingPage: 'Landing page',
        link: 'Link',
        notConfirmed: 'Not confirmed',
        sendCode: 'Send code',
        sendEmail: 'Send email',
        emailConfirmed: 'Your email confirmed',
        phoneConfirmed: 'Your phone number confirmed',
        personalDetails: 'Personal details',
        billingDetails: 'Billing details',
        selectCountry: 'Select a country',
        state: 'State',
        city: 'City',
        address: 'Address',
        zip: 'Zip Code',
        dob: 'Date of Birth',
        changePassword: 'Change Password',
        currentPassword: 'Current Password',
        newPassword: 'New Password',
        save: 'Save',
        courseVideos: 'Course videos',
        buyCourse: 'Buy course',
        chooseMethod: 'Choose payment method',
        pay: 'Pay',
        wtDetails: 'Wire Transfer Details',
        print: 'Print',
        promoCode: 'Promo code',
        disclaimer: 'Disclaimer: Trading financial instruments involve the risk of financial loss. Please consider carefully if such trading is appropriate for you. Past performance is not indicative of future results. Articles and content on this website are for educational purposes only and do not constitute investment recommendations, advice, or a guarantee of success. Coin College is an independent legal entity which provides services of online education only. Coin College has no connection to any other legal entity.',
        warning: 'Warning',
        pleaseLogIn: 'Enter your details to log into your Coin College account to access your course materials',
        registerNow: 'Register Now',
        hello: 'Hello! Please, tell us about yourself.',
        applyForAccount: 'Apply for an account',
        ourPrograms: 'Our programs is your main tool to maximize trading potential',
        free: 'Unlock for FREE!'
    },
    ar: {
        welcomeTo: 'مرحباً بك في',
        pleaseSignIn: 'يرجى تسجيل الدخول',
        email: 'البريد الإلكتروني',
        password: 'كلمة المرور',
        logIn: 'تسجيل الدخول',
        noAccount: 'لا تملك حساباً؟',
        signUp: 'التسجيل',
        forgotPassword: 'نسيت كلمة المرور',
        notRobot: '.نحن نثق بك ، ولكن لا يزال يتعين عليك إثبات أنك شخص حقيقي',
        pleaseSignUp: 'قم بالتسجيل رجاء',
        pleaseEmail: 'قم رجاء بإدخال بريدك الالكتروني',
        pleasePassword: 'قم رجاء بإدخال بريدك الالكتروني! ',
        pleaseFirstName: 'قم رجاءً بإدخال الاسم الأول!',
        pleaseLastName: 'قم رجاءً بإدخال الكنية!',
        firstName: 'الاسم الأول',
        lastName: 'الكنية',
        pleasePhone: 'قم رجاءً بإدخال رقم الهاتف!',
        phone: 'الهاتف',
        pleaseTerms: 'قم رجاءً بالموافقة على الشروط والأحكام',
        iAccept: 'أوافق على',
        tAndC: 'الشروط والأحكام',
        pleasePolicy: 'قم رجاءً بالموافقة على سياسة الخصوصية ',
        pp: 'سياسة الخصوصية',
        alreadyHave: 'تمتلك حساب مسبقاً؟',
        signIn: ' تسجيل الدخول',
        myCourses: 'الدورات التعليمية الخاصة بي',
        buyCourses: 'شراء دورات تعليمية',
        payments: 'الدفعات',
        affiliation: 'الإنتساب',
        profile: 'الملف الشخصي',
        logOut: 'تسجيل خروج',
        purchased: 'المشتريات',
        viewCourse: 'عرض الدورة التعليمية',
        buy: 'شراء',
        noCourses: 'لا يوجد لديك دورات تعليمية مشتراة',
        buyNow: 'قم بالشراء الآن',
        unexpectedError: 'حصل خطأ غير متوقع',
        course: 'الدورة التعليمية',
        created: 'تم الإنشاء',
        amount: 'المبلغ',
        currency: 'العملة',
        status: 'الحالة',
        new: 'جديد',
        pending: 'في الإنتظار',
        completed: 'تمت العملية بنجاح',
        failed: 'فشلت العملية',
        hoverStatus: 'توجه إلى الحالة لمعرفة التفاصيل',
        join: 'إنضم',
        name: 'الأسم',
        country: 'البلد',
        registrationDate: 'تاريخ التسجيل',
        date: 'التاريخ',
        banner: 'الإعلان',
        code: 'الرمز',
        action: 'الأمر',
        codeCopied: 'تم نسخ الرمز إلى الحافظة!',
        copyCode: 'نسخ الرمز',
        landingPage: 'الصفحة المطلوبة',
        link: 'الرابط',
        notConfirmed: 'غير مؤكد',
        sendCode: 'إرسال الرمز',
        sendEmail: 'إرسال البريد',
        emailConfirmed: 'تم تأكيد البريد الإلكتروني الخاص بك',
        phoneConfirmed: 'تم تأكيد رقم الهاتف الخاص بك',
        personalDetails: 'تفاصيل شخصية',
        billingDetails: 'تفاصيل الفاتورة',
        selectCountry: 'قم باختيار البلد',
        state: 'الولاية',
        city: 'المدينة',
        address: 'العنوان',
        zip: 'الرمز البريدي',
        dob: 'تاريخ الميلاد',
        changePassword: 'تغيير كلمة المرور',
        currentPassword: 'كلمة المرور الحالية',
        newPassword: 'كلمة مرور جديدة',
        save: 'حفظ',
        courseVideos: 'فيديوهات الدورة التعليمية',
        buyCourse: 'شراء دورة تعليمية',
        chooseMethod: 'قم باختيار طريقة الدفع',
        pay: 'ادفع',
        wtDetails: 'معلومات الحوالة البنكية',
        print: 'طباعة',
        promoCode: 'رمز ترويجي',
        disclaimer: '‘إخلاء المسؤولية: : إن تداول الأدوات المالية ينطوي على خطر الخسارة. يرجى الإطلاع بعناية إذا كان نوع التداول هذا مناسبًا لك. الأداء السابق ليس مؤشرا على النتائج المستقبلية. المقالات والمحتوى على هذا الموقع للأغراض التعليمية فقط ولا تشكل توصيات استثمارية أو نصائح أو ضمانًا للنجاح. إن فينان كوليدج كيان قانوني مستقل يقدم خدمات التعليم عبر الإنترنت فقط. لا علاقة لـ فينان كوليدج بأي كيان قانوني آخر.',
        warning: 'تنبيه',
        pleaseLogIn: 'فم بإدخال معلوماتك لتسجيل الدخول لحسابك في فينان كوليدج والولوج إلى المواد الخاصة بدورتك التعليمية',
        registerNow: 'سجل الآن',
        hello: 'مرحباً! لطفاً، أخبرنا المزيد عن نفسك.',
        applyForAccount: 'قدم للحصول على حساب',
        ourPrograms: 'إن برامجنا هي أداتك الرئيسية لزيادة فرص التداول',
        free: 'إفتح مجانا!'
    },
    ru: {
        welcomeTo: 'Добро пожаловать в',
        pleaseSignIn: 'Пожалуйста, войдите',
        email: 'Почта',
        password: 'Пароль',
        logIn: 'Войти',
        noAccount: 'Не зарегистрированы?',
        signUp: 'Зарегистрироваться',
        forgotPassword: 'Забыли пароль',
        notRobot: 'Мы вам верим, но всё же нужно подтвердить, что вы не робот.',
        pleaseSignUp: 'Пожалуйста, зарегистрируйтесь',
        pleaseEmail: 'Пожалуйста, введите вашу почту!',
        pleasePassword: 'Пожалуйста, введите ваш пароль!',
        pleaseFirstName: 'Пожалуйста, введите ваше имя!',
        pleaseLastName: 'Пожалуйста, введите вашу фамилию!',
        firstName: 'Имя',
        lastName: 'Фамилия',
        pleasePhone: 'Пожалуйста, введите ваш номер телефона!',
        phone: 'Телефон',
        pleaseTerms: 'Пожалуйста, примите условия пользования',
        iAccept: 'Я принимаю',
        tAndC: 'условия пользования',
        pleasePolicy: 'Пожалуйста, примите политику конфиденциальности',
        pp: 'политика конфиденциальности',
        alreadyHave: 'Уже есть аккаунт?',
        signIn: 'Войти',
        myCourses: 'Мои курсы',
        buyCourses: 'Купить курс',
        payments: 'Платежи',
        affiliation: 'Партнерская программа',
        profile: 'Моя страница',
        logOut: 'Выйти',
        purchased: 'Покупки',
        viewCourse: 'Посмотреть курс',
        buy: 'Купить',
        noCourses: 'У вас нет купленных курсов',
        buyNow: 'Купить сейчас',
        unexpectedError: 'Возникла непредвиденная ошибка',
        course: 'Курс',
        created: 'Создать',
        amount: 'Сумма',
        currency: 'Валюта',
        status: 'Статус',
        new: 'Новое',
        pending: 'В ожидании',
        completed: 'Завершен',
        failed: 'Неудачно',
        hoverStatus: 'Наведите указатель мыши, чтобы получить подробную информацию',
        join: 'Присоединиться',
        name: 'Имя',
        country: 'Страна',
        registrationDate: 'Дата регистрации',
        date: 'Дата',
        banner: 'Баннер',
        code: 'Код',
        action: 'Действие',
        codeCopied: 'Код скопирован в буфер обмена!',
        copyCode: 'Скопировать код',
        landingPage: 'Целевая страница',
        link: 'Ссылка',
        notConfirmed: 'Не подтвержден',
        sendCode: 'Отправить код',
        sendEmail: 'Отправить письмо',
        emailConfirmed: 'Ваш электронный адрес подтвержден',
        phoneConfirmed: 'Ваш номер телефона подтвержден',
        personalDetails: 'Личные данные',
        billingDetails: 'Платежные реквизиты',
        selectCountry: 'Выберите страну',
        state: 'Регион',
        city: 'Город',
        address: 'Адрес',
        zip: 'Почтовый индекс',
        dob: 'Дата рождения',
        changePassword: 'Изменить пароль',
        currentPassword: 'Текущий пароль',
        newPassword: 'Новый пароль',
        save: 'Сохранить',
        courseVideos: 'Видео курса',
        buyCourse: 'Купить курс',
        chooseMethod: 'Выбрать способ оплаты',
        pay: 'Оплатить',
        wtDetails: 'Детали банковского перевода',
        print: 'Распечатать',
        promoCode: 'Промо код',
        disclaimer: 'Торговля финансовыми инструментами сопряжена с риском финансовых потерь. Пожалуйста, подумайте, подходит ли вам такая торговля. Прошлые показатели не свидетельствуют о будущих результатах. Статьи и контент на этом веб-сайте предназначены только для образовательных целей и не являются инвестиционными рекомендациями, советами или гарантией успеха. Coin College - это независимое юридическое лицо, которое предоставляет только услуги онлайн-образования. Coin College не имеет отношения к другому юридическому лицу.',
        warning: 'Предупреждение',
        pleaseLogIn: 'Введите свои данные, чтобы войти в свою учетную запись Financollage и получить доступ к материалам курса.',
        registerNow: 'Зарегистрироваться сейчас',
        hello: 'Привет! Расскажите, пожалуйста, о себе.',
        applyForAccount: 'Подать заявку на регистрацию',
        ourPrograms: 'Наши программы - ваш главный инструмент для максимального увеличения торгового потенциала',
        free: 'Разблокируйте БЕСПЛАТНО!'
    },
    de: {
        welcomeTo: 'Willkommen bei',
        pleaseSignIn: 'Bitte einloggen',
        email: 'E-Mail',
        password: 'Passwort',
        logIn: 'Log-in',
        noAccount: 'Sie haben kein Konto?',
        signUp: 'Registrieren',
        forgotPassword: 'Passwort vergessen',
        notRobot: 'Wir vertrauen Ihnen, Sie müssen aber dennoch beweisen, dass Sie kein Bot sind.',
        pleaseSignUp: 'Bitte registrieren',
        pleaseEmail: 'Bitte Ihre E-Mail-Adresse eingeben!',
        pleasePassword: 'Bitte Ihr Passwort eingeben!',
        pleaseFirstName: 'Bitte Ihren Vornamen eingeben!',
        pleaseLastName: 'Bitte Ihren Nachnamen eingeben!',
        firstName: 'Vorname',
        lastName: 'Nachname',
        pleasePhone: 'Bitte Ihre Telefonnummer eingeben!',
        phone: 'Telefonnummer',
        pleaseTerms: 'Akzeptieren Sie bitte unsere Allgemeinen Geschäftsbedingungen',
        iAccept: 'Ich akzeptiere',
        tAndC: 'Allgemeine Geschäftsbedingungen',
        pleasePolicy: 'Akzeptieren Sie bitte unsere Datenschutzrichtlinie',
        pp: 'Datenschutzrichtlinie',
        alreadyHave: 'Sie haben bereits ein Konto?',
        signIn: 'Anmelden',
        myCourses: 'Meine Kurse',
        buyCourses: 'Kurse kaufen',
        payments: 'Zahlungen',
        affiliation: 'Zugehörigkeit',
        profile: 'Profil',
        logOut: 'Log-out',
        purchased: 'Erworben',
        viewCourse: 'Kursansicht',
        buy: 'Kaufen',
        noCourses: 'Sie haben keine erworbenen Kurse',
        buyNow: 'Jetzt kaufen',
        unexpectedError: 'Ein unerwarteter Fehler ist aufgetreten',
        course: 'Kurs',
        created: 'Erstellt',
        amount: 'Betrag',
        currency: 'Währung',
        status: 'Status',
        new: 'Neu',
        pending: 'Ausstehend',
        completed: 'Abgeschlossen',
        failed: 'Fehlgeschlagen',
        hoverStatus: 'Bewegen Sie Ihre Maus über den Status, um weitere Angaben zu erhalten',
        join: 'Beitreten',
        name: 'Name',
        country: 'Land',
        registrationDate: 'Registrierungsdatum',
        date: 'Datum',
        banner: 'Banner',
        code: 'Code',
        action: 'Handlung',
        codeCopied: 'Code auf das Clipboard kopiert!',
        copyCode: 'Code kopieren',
        landingPage: 'Zielseite',
        link: 'Link',
        notConfirmed: 'Nicht bestätigt',
        sendCode: 'Code senden',
        sendEmail: 'E-Mail senden',
        emailConfirmed: 'Ihre E-Mail ist bestätigt',
        phoneConfirmed: 'Ihre Telefonnummer ist bestätigt',
        personalDetails: 'Persönliche Angaben',
        billingDetails: 'Rechnungsdaten',
        selectCountry: 'Land auswählen',
        state: 'Bundesstaat',
        city: 'Stadt',
        address: 'Adresse',
        zip: 'Postleitzahl',
        dob: 'Geburtsdatum',
        changePassword: 'Passwort ändern',
        currentPassword: 'Aktuelles Passwort',
        newPassword: 'Neues Passwort',
        save: 'Speichern',
        courseVideos: 'Kursvideos',
        buyCourse: 'Kurs kaufen',
        chooseMethod: 'Zahlungsmethode auswählen',
        pay: 'Zahlen',
        wtDetails: 'Angaben für Banküberweisung',
        print: 'Ausdrucken',
        promoCode: 'Werbeaktionscode',
        disclaimer: 'Haftungsausschluss: Der Handel mit Finanzinstrumenten beinhaltet das Risiko für finanzielle Verluste. Bedenken Sie bitte, ob ein diesbezüglicher Handel für Sie angemessen ist. Vergangene Leistungen sind kein Indiz für zukünftige Ergebnisse. Die Artikel und Inhalte auf dieser Webseite dienen ausschließlich dem Edukationszweck und stellen keine Anlageempfehlungen, Beratung oder Erfolgsgarantien dar. Coin College ist ein unabhängiger Rechtsträger, welcher ausschließlich eine Online-Edukationsdienstleistung anbietet. Coin College steht in keiner Verbindung zu anderen Rechtsträgern. ',
        warning: 'Warnung',
        pleaseLogIn: 'Geben Sie Ihre Log-in-Angaben ein, um Zugriff auf Ihr Coin College-Konto und Ihre Kursmaterialien zu erhalten.',
        registerNow: 'Jetzt registrieren',
        hello: 'Hallo! Sagen Sie uns bitte mehr über sich.',
        applyForAccount: 'Für ein Konto anmelden',
        ourPrograms: 'Unsere Lehrprogramme sind Ihr Tool für die Optimierung Ihres Handelspotenzials',
        free: 'KOSTENLOS freischalten!'
    },
    sk: {
        welcomeTo: 'Vitajte v',
        pleaseSignIn: 'Prosím prihláste sa',
        email: 'Email',
        password: 'Heslo',
        logIn: 'Prihlásiť sa',
        noAccount: 'Nemáte účet?',
        signUp: 'Zaregistrujte sa',
        forgotPassword: 'Zabudli ste heslo',
        notRobot: 'Veríme vám, ale napriek tomu musíte dokázať, že nie ste robot.',
        pleaseSignUp: 'Prosím, zaregistrujte sa',
        pleaseEmail: 'Zadajte vaše e-mail!',
        pleasePassword: 'Zadajte vaše heslo!',
        pleaseFirstName: 'Zadajte vaše krstné meno!',
        pleaseLastName: 'Zadajte vaše priezvisko!',
        firstName: 'Krstné meno',
        lastName: 'Priezvisko',
        pleasePhone: 'Zadajte svoj telefón!',
        phone: 'Telefón',
        pleaseTerms: 'Prijmite, prosím, zmluvné podmienky',
        iAccept: 'Súhlasím',
        tAndC: 'Obchodné podmienky',
        pleasePolicy: 'Prijmite, prosím, zásady ochrany osobných údajov',
        pp: 'Zásady ochrany osobných údajov',
        alreadyHave: 'Máte už účet?',
        signIn: 'Prihlásiť sa',
        myCourses: 'Moje kurzy',
        buyCourses: 'Kúpte si kurzy',
        payments: 'Platby',
        affiliation: 'Príslušnosť',
        profile: 'Profil',
        logOut: 'Odhlásiť sa',
        purchased: 'Zakúpené',
        viewCourse: 'Zobraziť kurz',
        buy: 'Kúpiť',
        noCourses: 'Nemáte zakúpené žiadne kurzy',
        buyNow: 'Kúpiť teraz',
        unexpectedError: 'Nastala neočakávaná chyba',
        course: 'Kurz',
        created: 'Vytvorené',
        amount: 'Množstvo',
        currency: 'Mena',
        status: 'Stav',
        new: 'Stav',
        pending: 'Čakajúca',
        completed: 'Dokončené',
        failed: 'Zlyhanie',
        hoverStatus: 'Umiestnením kurzora myši zobrazíte podrobnosti',
        join: 'Pripojte sa',
        name: 'Názov',
        country: 'Krajina',
        registrationDate: 'Dátum registrácie',
        date: 'Dátum',
        banner: 'Banner',
        code: 'Kód',
        action: 'Akcia',
        codeCopied: 'Kód bol skopírovaný do schránky!',
        copyCode: 'Skopírujte kód',
        landingPage: 'Cieľová stránka',
        link: 'Odkaz',
        notConfirmed: 'Nepotvrdené',
        sendCode: 'Pošlite kód',
        sendEmail: 'Poslať email',
        emailConfirmed: 'Váš e-mail bol potvrdený',
        phoneConfirmed: 'Vaše telefónne číslo bolo potvrdené',
        personalDetails: 'Osobné údaje',
        billingDetails: 'Fakturačné údaje',
        selectCountry: 'Vyberte krajinu',
        state: 'Štát',
        city: 'Mesto',
        address: 'Adresa',
        zip: 'PSČ',
        dob: 'Dátum narodenia',
        changePassword: 'Zmeniť heslo',
        currentPassword: 'Aktuálne heslo',
        newPassword: 'Nové heslo',
        save: 'Uložiť',
        courseVideos: 'Videá z kurzov',
        buyCourse: 'Kúpiť kurz',
        chooseMethod: 'Vyberte si metódu platby',
        pay: 'Zaplatiť',
        wtDetails: 'Podrobnosti o bankovom prevode',
        print: 'Tlač',
        promoCode: 'Promo kód',
        disclaimer: 'Zrieknutie sa zodpovednosti: Obchodovanie s finančnými nástrojmi predstavuje riziko finančnej straty. Dôsledne zvážte, či je takéto obchodovanie pre vás vhodné. Minulá výkonnosť nie je ukazovateľom budúcich výsledkov. Články a obsah na tejto webovej stránke slúžia iba na informačné účely a nepredstavujú investičné odporúčania, rady ani záruku úspechu. Finacollege je nezávislý právny subjekt, ktorý poskytuje iba služby online vzdelávania. Coin College nie je prepojený s nijakým iným právnym subjektom.',
        warning: 'Varovanie',
        pleaseLogIn: 'Zadajte vaše údaje a prihláste sa do svojho účtu Coin College, aby ste získali prístup k materiálom ku kurzom',
        registerNow: 'Zaregistrujte sa teraz',
        hello: 'Dobrý deň! Prosím, povedzte nám niečo o sebe.',
        applyForAccount: 'Požiadať o účet',
        ourPrograms: 'Naše programy sú vašim hlavným nástrojom pre maximalizáciu obchodného potenciálu',
        free: 'Odomknite ZADARMO!'
    }
}
