class User {

  constructor (){
    if (localStorage.getItem('user')) {
      this.data = JSON.parse(localStorage.getItem('user'));
    } else {
      this.data = false;
    }
  }

  get = (key = false) => {
    if (!key) return this.data;
    try {
      return this.data[key];
    } catch (e){
      return this.data;
    }
  }

  authorize = (data) => {
    const object = JSON.stringify(data);
    localStorage.setItem('user', object);
    window.parent.postMessage({user: true}, '*');
    return JSON.parse(object);
  }

}

export default User;